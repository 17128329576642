import React from 'react';
import './AboveMain.css';

const AboveMain = () => {
    return (
            <div className="AboveMain">
            </div>
    );
}

export default AboveMain;