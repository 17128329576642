// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AboveMain {
    width: 100%;
    max-width: 1920px;
    height: 160px;
    opacity: 1;
    background-color: #37517E;
}`, "",{"version":3,"sources":["webpack://./src/AboveMain/AboveMain.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,iBAAiB;IACjB,aAAa;IACb,UAAU;IACV,yBAAyB;AAC7B","sourcesContent":[".AboveMain {\n    width: 100%;\n    max-width: 1920px;\n    height: 160px;\n    opacity: 1;\n    background-color: #37517E;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
